.website-form input:not([type='submit']) {
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  padding: 0 5px !important;
  height: 32px !important;
}

.website-form input:not([type='submit'])::placeholder {
  color: #BFBFBF !important;
}

.website-form input:not([type='submit']):focus {
  border: 1px solid #009587 !important;
  border-bottom: 3px solid #009587 !important;
  box-shadow: none !important;
}

.website-form .errors {
  background-color: #f44336 !important;
  color: rgba(255, 255, 255, 0.84) !important;
}

.website-form .success-messages {
  background-color: #4caf50 !important;
  color: rgba(255, 255, 255, 0.84) !important;
}

.or-wrap {
  text-align: center;
  position: relative;
}

.or-wrap .text {
  padding: 0 22px;
  position: relative;
  display: inline-block;
  background-color: #fff;
}

.or-wrap:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #E1E0E0;
  background: #E1E0E0;
  width: 100%;
  transform: translateY(-50%);
}

.alert.error {
  background-color: #f44336 !important;
  color: rgba(255, 255, 255, 0.84) !important;
}

.alert.error a {
  color: inherit;
}

.alert.success {
  background-color: #4caf50 !important;
  color: rgba(255, 255, 255, 0.84) !important;
}

.alert.success a {
  color: inherit;
}

#modal-pop-up-smarter-click h2,
#modal-pop-up-smarter-click h3 {
  font-size: 20px !important;
}

#modal-pop-up-smarter-click h1 {
  font-size: 35px !important;
}

#modal-pop-up-smarter-click .btn-info {
  background-color: #3697fb !important;
}

#modal-pop-up-smarter-click .btn-success {
  background-color: #83e24f !important;
  border-color: #83e24f !important;
}

#modal-pop-up-smarter-click .form-check,
#modal-pop-up-smarter-click .form-check * {
  cursor: pointer;
}

#modal-pop-up-smarter-click .error {
  border-color: #f44336 !important;
}

#modal-pop-up-country-select .modal-content {
  box-shadow: 0 0 10px #000;
  -webkit-box-shadow: 0 0 10px #000;
}

#modal-pop-up-country-select .modal-content .modal-body {
  padding: 15px 30px;
}

#modal-cookies .modal-dialog {
  max-width: 600px;
}

#modal-cookies .modal-content {
  border-radius: 0;
}

#modal-cookies .ok-button {
  height: 58px !important;
}

@media (min-width: 992px) {
  #modal-cookies .ok-button {
    width: 400px !important;
    height: 44px !important;
  }
}

#modal-cookies .tvi-logo-mobile {
  font-size: 52px;
}

#modal-config-cookies .modal-content {
  max-height: 75%;
  border-radius: 10px;
}

#modal-config-cookies .modal-content .form-switch {
  padding-left: 60px;
  min-height: 30px;
}

#modal-config-cookies .modal-content .form-switch input {
  margin-left: -60px;
  width: 60px;
  height: 25px;
}

#modal-config-cookies .modal-content .form-switch label {
  opacity: 1;
}

.old-header #modal-config-cookies .modal-body {
  padding: 1rem;
}

#cookies-message a {
  color: #009688;
  text-decoration: none;
}

#cookies-message a:hover {
  text-decoration: underline;
}

@media (max-width: 992px) {
  #cookies-message .buttons {
    padding-top: 20px;
  }
}

#modal-config-cookies .icon {
  width: 40px;
}

#modal-config-cookies a {
  color: #009688;
  text-decoration: none;
}

#modal-config-cookies a:hover {
  text-decoration: underline;
}

#modal-config-cookies .form-check,
#modal-config-cookies .form-check * {
  cursor: pointer;
}

.modal :not(.modal-dialog-scrollable) .modal-content {
  height: 100%;
}

.modal :not(.modal-dialog-scrollable) .modal-content .modal-body {
  overflow-y: auto;
}

@media (min-width: 992px) {
  .modal .modal-lg-95 {
    max-width: 95%;
    height: 95%;
  }

  .modal .modal-lg-600 {
    max-width: 600px;
  }

  .modal .modal-lg-650 {
    max-width: 650px;
  }

  .modal .modal-lg-900 {
    max-width: 900px;
  }

  .modal .modal-lg-h-90 {
    height: 90%;
  }
}

.overlay {
  background: #000;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
}

.bottom-90px {
  bottom: 90px;
}

.top-45 {
  top: 45%;
}

.position-inherit {
  position: inherit;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: inherit;
}

@media (min-width: 576px) {
  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-inherit {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

@media (min-width: 768px) {
  .position-md-relative {
    position: relative !important;
  }

  .position-md-inherit {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

@media (min-width: 992px) {
  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-inherit {
    position: inherit !important;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

@media (min-width: 1200px) {
  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-inherit {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

@media (min-width: 1400px) {
  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-inherit {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

#product-page .complement img,
#product-page .cross-selling img,
#modal-product .complement img,
#modal-product .cross-selling img {
  width: 70px;
}

@media (min-width: 576px) {
  #product-page .complement img,
  #product-page .cross-selling img,
  #modal-product .complement img,
  #modal-product .cross-selling img {
    width: 100px;
  }
}

#product-page .complement.has-image .name,
#product-page .cross-selling.has-image .name,
#modal-product .complement.has-image .name,
#modal-product .cross-selling.has-image .name {
  width: calc(100% - 75px);
}

@media (min-width: 576px) {
  #product-page .complement.has-image .name,
  #product-page .cross-selling.has-image .name,
  #modal-product .complement.has-image .name,
  #modal-product .cross-selling.has-image .name {
    width: calc(100% - 105px);
  }
}

.blinker-superfast {
  animation: blink .2s linear infinite;
}

.blinker-fast {
  animation: blink .5s linear infinite;
}

.blinker-normal {
  animation: blink 1s linear infinite;
}

.blinker-slow {
  animation: blink 2s linear infinite;
}

@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

#popup-newsletter .copied-voucher i {
  color: #4daf50 !important;
}

#popup-newsletter .copied-voucher i:before {
  content: "\F00C" !important;
}

.offcanvas .offcanvas-header h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

@media (min-width: 992px) {
  .offcanvas .offcanvas-header h3 {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .offcanvas.offcanvas-lg-end {
    top: 0;
    right: 0;
    left: auto;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    transform: translateX(100%);
    width: 400px;
    height: 100% !important;
  }

  .offcanvas.offcanvas-lg-end.show {
    transform: none;
  }
}

[data-bs-toggle="collapse"] .direction-icon:before,
.box-collapse .header .direction-icon:before {
  content: "\F078";
}

[data-bs-toggle="collapse"] .direction-icon.reverse:before,
.box-collapse .header .direction-icon.reverse:before {
  content: "\F077";
}

[data-bs-toggle="collapse"][aria-expanded="true"] .direction-icon,
.box-collapse .header[aria-expanded="true"] .direction-icon {
  transform: rotate(180deg);
}

[data-bs-toggle="collapse"] .symbol-icon:before,
.box-collapse .header .symbol-icon:before {
  content: "\F067";
}

[data-bs-toggle="collapse"][aria-expanded="true"] .symbol-icon:before,
.box-collapse .header[aria-expanded="true"] .symbol-icon:before {
  content: "\F068";
}

#cart-progress {
  position: relative;
}

#cart-progress .step-progress {
  position: absolute;
  height: 5px;
  background-color: #E1E0E0;
  z-index: -1;
  left: 50%;
  top: 15px;
  transform: translateX(-50%) !important;
}

#cart-progress .step-progress .bar {
  height: 100%;
  background-color: #D6236F;
}

#cart-progress .step {
  text-align: center;
  flex: 1 0 0%;
  display: flex;
  justify-content: center !important;
}

#cart-progress .step .step-number {
  width: 35px;
  line-height: 35px;
  display: block;
  margin: auto;
  background-color: #9D9D9D;
  border-radius: 50%;
  color: #FFF;
}

#cart-progress .step .step-name {
  font-weight: bold;
  color: #9D9D9D;
  word-wrap: break-word;
  word-break: break-word;
}

#cart-progress .step.active .step-number {
  background-color: #D6236F;
}

#cart-progress .step.active .step-name {
  color: #D6236F;
}

.review-stars {
  background-image: url(/build/images/stars.921f1706.png);
  background-repeat: no-repeat;
  width: 75px;
  height: 11px;
}

.review-stars.stars-empty {
  background-position: -2px -2px;
}

.review-stars.stars-filled {
  background-position: -2px -17px;
}

.review-stars-new {
  background-image: url(/build/images/stars.921f1706.png) !important;
}

.review-stars-gold {
  background-image: url(/build/images/stars-gold.c7bbe49d.png) !important;
}

.cart-item .cart-item-img {
  aspect-ratio: 1/1;
  text-align: center;
}

.cart-item .cart-item-img img {
  max-width: 100%;
  max-height: 100%;
}

.cart-item .cart-item-option {
  display: block;
  vertical-align: middle;
}

.cart-item .cart-item-option.type-color {
  float: left;
  margin: 3px 3px 0 0;
  width: 11px;
  height: 11px;
  border: 1px solid #000;
  border-radius: 50%;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.cart-item .cart-item-option.type-text {
  font-style: italic;
}

.cart-item .cart-item-quantity select {
  font-weight: bold;
}

.cart-item .cart-item-quantity select option {
  font-weight: bold;
}

.cart-item .cart-item-actions button {
  width: 40px;
  aspect-ratio: 1/1;
}

@media (min-width: 992px) {
  #cart {
    width: 550px !important;
  }
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 1 !important;
}


.search-form .suggestions {
  box-shadow: 5px 5px 10px -5px #000000;
}

@media (min-width: 992px) {
  .search-form .suggestions {
    margin-right: 0.75rem;
  }
}

.search-form .suggestions .suggestion {
  cursor: pointer;
}

.search-form .suggestions .suggestion .suggestion-name {
  font-weight: bold;
}

.search-form .suggestions .suggestion .suggestion-name span {
  font-weight: normal;
}

.search-form .suggestions .suggestion.saved-search .remove-saved-search {
  color: #adb5bd;
  vertical-align: top;
}

.search-form .suggestions .suggestion.saved-search .suggestion-name {
  color: #4555b7;
}

.search-form .suggestions .suggestion:hover {
  background-color: #EEEEEE;
}

.search-form .suggestions .suggestion .remove-saved-search {
  cursor: pointer;
}

.search-form .searcher .dropdown-content {
  outline: 1px solid #000000;
}

.search-form .searcher .dropdown-option:hover,
.search-form .searcher .dropdown-option.selected {
  background-color: #E7E7E7;
  font-weight: bold;
}

.search-form .searcher .btn-clear i {
  background-color: #cdcdcd;
  line-height: 25px;
  width: 25px;
}

#left-menu {
  width: 95%;
}

@media (min-width: 992px) {
  #left-menu {
    width: 420px;
  }
}

#left-menu a {
  color: #000000;
  text-decoration: none;
}

#left-menu #country-selector {
  max-height: 35%;
  overflow: auto;
}

#left-menu .hm-section {
  display: none;
}

#left-menu .hm-section.active {
  display: block;
}

#left-menu .hm-section .hm-back {
  cursor: pointer;
}

#left-menu .hm-section .hm-back i {
  padding-right: 6px;
}

#left-menu .hm-section .hm-navigate {
  cursor: pointer;
}

#left-menu .hm-section li {
  margin: 7px 0;
}

@media (min-width: 992px) {
  #left-menu .hm-section li {
    margin: 10px 0;
  }
}

#left-menu .hm-section li.i-1 {
  padding-left: 5px;
}

#left-menu .hm-section li.i-2 {
  padding-left: 10px;
}

#left-menu .hm-section li.i-3 {
  padding-left: 15px;
}

#left-menu .hm-section[data-type="main"] li {
  margin: 15px 0 !important;
}

#left-menu .hm-section[data-type="productType"] li {
  margin: 10px 0 !important;
}

.left-menu .highlight {
  background-color: #d8b545;
  text-transform: uppercase;
}

.left-menu .content {
  transition: transform 0.3s 0s;
  transform: translate3d(-100%, 0, 0);
}

.left-menu.visible {
  visibility: visible;
  transition: visibility 0s 0s;
}

.left-menu.visible .content {
  transform: translate3d(0, 0, 0);
}

.left-menu .countries-dropdown .close {
  -webkit-box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
  -moz-box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
  box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
}

.left-menu .bg-gray {
  background-color: #E4E4E4;
}

.left-menu .close-personalized-links i:before {
  content: "\F139";
}

.left-menu .open-personalized-links i:before {
  content: "\F13A";
}

#header #header-reviews .content,
header #header-reviews .content {
  width: 500px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2), 0 1px 6px rgba(0, 0, 0, 0.2);
}

#header #header-countries .content,
#header #header-languages .content,
header #header-countries .content,
header #header-languages .content {
  width: 200%;
  max-height: 350px;
}

#header #header-countries .content .element:hover,
#header #header-languages .content .element:hover,
header #header-countries .content .element:hover,
header #header-languages .content .element:hover {
  background-color: #5278ce !important;
}

#header #header-user .content,
#header .user .content,
header #header-user .content,
header .user .content {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2), 0 1px 6px rgba(0, 0, 0, 0.2);
}

#header #header-user.user-logged .content,
#header .user.user-logged .content,
header #header-user.user-logged .content,
header .user.user-logged .content {
  width: 250px;
}

#header #header-user.user-register .content,
#header .user.user-register .content,
header #header-user.user-register .content,
header .user.user-register .content {
  width: 600px;
}

#header .header-dropdown .content,
header .header-dropdown .content {
  cursor: auto !important;
}

#header #header-bottom .personalized-element,
header #header-bottom .personalized-element {
  background-color: #e4e4e4;
  border: 1px solid #c5c5c5;
}

header .search-form .suggestions,
.header-searcher .search-form .suggestions {
  padding-top: 40px;
  box-shadow: 0 9px 8px rgba(0, 0, 0, 0.15);
}

#loyalty-newsletter {
  transition: visibility 0.5s linear,opacity 0.5s linear;
}


.social-icon {
  background-image: url(/build/images/spritesheet.cfebfc45.png);
  background-repeat: no-repeat;
  display: block;
}

.social-icon.si-blog {
  background-position: -35px -32px;
  width: 30px;
  height: 30px;
}

.social-icon.si-facebook {
  background-position: -2px -3px;
  width: 30px;
  height: 30px;
}

.social-icon.si-instagram {
  background-position: -35px -1px;
  width: 30px;
  height: 30px;
}

.social-icon.si-pinterest {
  background-position: 0px -63px;
  width: 30px;
  height: 30px;
}

.social-icon.si-x {
  background-position: -108px -11px;
  width: 30px;
  height: 30px;
}

.social-icon.si-youtube {
  background-position: -66px -31px;
  width: 30px;
  height: 30px;
}

.social-icon.si-linkedin {
  background-position: -65px -2px;
  width: 30px;
  height: 30px;
}

.social-icon.si-tiktok {
  background-position: -30px -63px;
  width: 30px;
  height: 30px;
}

footer a {
  transition: all .2s;
}

footer a:hover {
  opacity: .7;
}

footer #footer-social .social-link {
  transition: transform .2s;
}

footer #footer-social .social-link:hover {
  transform: scale(1.5);
}

footer #footer-payment img {
  max-height: 30px;
}


