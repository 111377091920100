@mixin get-social-icon($x, $y, $width, $height) {
  background-position: $x $y;
  width: $width;
  height: $height;
}

.social-icon {
  background-image: url(../../../images/website/social/spritesheet.png);
  background-repeat: no-repeat;
  display: block;

  &.si-blog {
    @include get-social-icon(-35px, -32px, 30px, 30px);
  }

  &.si-facebook {
    @include get-social-icon(-2px, -3px, 30px, 30px);
  }

  &.si-instagram {
    @include get-social-icon(-35px, -1px, 30px, 30px);
  }

  &.si-pinterest {
    @include get-social-icon(-0px, -63px, 30px, 30px);
  }

  &.si-x {
    @include get-social-icon(-108px, -11px, 30px, 30px);
  }

  &.si-youtube {
    @include get-social-icon(-66px, -31px, 30px, 30px);
  }

  &.si-linkedin {
    @include get-social-icon(-65px, -2px, 30px, 30px);
  }

  &.si-tiktok {
    @include get-social-icon(-30px, -63px, 30px, 30px);
  }
}
