#left-menu {
  width: 95%;

  @media (min-width: $break-lg) {
    width: 420px;
  }

  a {
    color: #000000;
    text-decoration: none;
  }

  #country-selector {
    max-height: 35%;
    overflow: auto;
  }

  .hm-section {
    display: none;
    &.active {
      display: block;
    }

    .hm-back {
      cursor: pointer;
      i {
        padding-right: 6px;
      }
    }
    .hm-navigate {
      cursor: pointer;
    }

    li {
      margin: 7px 0;

      @media (min-width: $break-lg) {
        margin: 10px 0;
      }
      &.i-1 {
        padding-left: 5px;
      }
      &.i-2 {
        padding-left: 10px;
      }
      &.i-3 {
        padding-left: 15px;
      }
    }

    &[data-type="main"] {
      li {
        margin: 15px 0!important;
      }
    }
    &[data-type="productType"] {
      li {
        margin: 10px 0!important;
      }
    }
  }
}

//TODO REMOVE - OLD HEADER
.left-menu {
  .highlight {
    background-color: #d8b545;
    text-transform: uppercase;
  }

  .content {
    transition: transform 0.3s 0s;
    transform: translate3d(-100%, 0, 0);
  }

  &.visible {
    visibility: visible;
    transition: visibility 0s 0s;

    .content {
      transform: translate3d(0, 0, 0);
    }
  }

  .countries-dropdown .close{
    -webkit-box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
    -moz-box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
    box-shadow: 0px -10px 40px rgba(255, 255, 255, 0.25);
  }

  .bg-gray {
    background-color: #E4E4E4;
  }

  .close-personalized-links i:before {
      content: "\F139";
  }

  .open-personalized-links i:before {
    content: "\F13a";
  }
}