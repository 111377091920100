@import "../z-index-vars";
@import "../responsive-vars";
@import "searcher";
@import "../LeftMenu/main";

#header, header {
  #header-reviews {
    .content {
      width: 500px;
      box-shadow: 0 1px 6px rgba(0,0,0,0.2),0 1px 6px rgba(0,0,0,.2);
    }
  }

  #header-countries, #header-languages {
    .content {
      width: 200%;
      max-height: 350px;

      .element:hover {
        background-color: #5278ce !important;
      }
    }
  }

  #header-user, .user {
    .content {
      box-shadow: 0 1px 6px rgba(0,0,0,0.2),0 1px 6px rgba(0,0,0,.2);
    }

    &.user-logged .content {
      width: 250px;
    }

    &.user-register .content {
      width: 600px;
    }
  }

  .header-dropdown {
    .content {
      cursor: auto !important;
    }
  }

  #header-bottom {
    .personalized-element {
      background-color: #e4e4e4;
      border: 1px solid #c5c5c5;
    }
  }
}

header, .header-searcher {
  .search-form {
    .suggestions {
      padding-top: 40px;
      box-shadow: 0 9px 8px rgba(0, 0, 0, 0.15);
    }
  }
}

#loyalty-newsletter {
  transition:visibility 0.5s linear,opacity 0.5s linear;
}