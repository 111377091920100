.cart-item {
  .cart-item-img {
    aspect-ratio: 1/1;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .cart-item-option {
    display: block;
    vertical-align: middle;

    &.type-color {
      float: left;
      margin: 3px 3px 0 0;
      width: 11px;
      height: 11px;
      border: 1px solid #000;
      border-radius: 50%;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
    &.type-text {
      font-style: italic;
    }
  }

  .cart-item-quantity {
    select {
      font-weight: bold;
      option {
        font-weight: bold;
      }
    }
  }

  .cart-item-actions {
    button {
      width: 40px;
      aspect-ratio: 1/1;
    }
  }
}