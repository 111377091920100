.modal {
  :not(.modal-dialog-scrollable) {
    .modal-content {
      height: 100%;

      .modal-body {
        overflow-y: auto;
      }
    }
  }

  @media (min-width: $break-lg) {
    .modal-lg-95 {
      max-width: 95%;
      height: 95%;
    }
    .modal-lg-600 {
      max-width: 600px;
    }

    .modal-lg-650 {
      max-width: 650px;
    }
    .modal-lg-900 {
      max-width: 900px;
    }

    .modal-lg-h-90 {
      height: 90%;
    }
  }
}