@import "text-size-vars";
.offcanvas {
  .offcanvas-header h3 {
    font-size: $big-text-size;
    font-weight: bold;
    margin: 0;

    @media (min-width: $break-lg) {
      font-size: $big-lg-text-size;
    }
  }

  @media (min-width: $break-lg) {
    &.offcanvas-lg-end {
      top: 0;
      right: 0;
      left: auto;
      border-left: 1px solid rgba(0, 0, 0, .2);
      border-top: none;
      transform: translateX(100%);
      width: 400px;
      height: 100% !important;

      &.show {
        transform: none;
      }
    }
  }
}