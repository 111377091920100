#modal-pop-up-smarter-click {
  h2, h3 {
    font-size: 20px !important;
  }

  h1 {
    font-size: 35px !important;
  }

  .btn-info {
    background-color: #3697fb !important;
  }

  .btn-success {
    background-color: #83e24f !important;
    border-color: #83e24f !important;
  }
  
  .form-check, .form-check * {
    cursor: pointer;
  }

  .error {
    border-color: #f44336 !important;
  }
}