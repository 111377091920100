[data-bs-toggle="collapse"], .box-collapse .header {
  .direction-icon {
    &:before {
      content: "\F078";
    }
    &.reverse:before {
      content: "\F077";
    }
  }

  &[aria-expanded="true"] .direction-icon {
    transform: rotate(180deg);
  }

  .symbol-icon:before {
    content: "\F067";
  }

  &[aria-expanded="true"] .symbol-icon:before {
    content: "\F068";
  }
}