@import "../responsive-vars";

#modal-config-cookies {
  .modal-content {
    max-height: 75%;
    border-radius: 10px;

    .form-switch {
      padding-left: 60px;
      min-height: 30px;

      input {
        margin-left: -60px;
        width: 60px;
        height: 25px;
      }

      label {
        opacity: 1;
      }
    }
  }
}

.old-header #modal-config-cookies {
  .modal-body {
    padding: 1rem;
  }
}