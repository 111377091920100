.review-stars {
  background-image: url(../../../images/website/reviews/stars.png);
  background-repeat: no-repeat;
  width: 75px;
  height: 11px;

  &.stars-empty {
    background-position: -2px -2px;
  }

  &.stars-filled {
    background-position: -2px -17px;
  }
}

.review-stars-new {
  background-image: url(../../../images/website/reviews/stars.png) !important;
}

.review-stars-gold {
  background-image: url(../../../images/website/reviews/stars-gold.png) !important;
}