#product-page ,#modal-product {
  .complement, .cross-selling {
    img {
      width: 70px;

      @media (min-width: $break-sm) {
        width: 100px;
      }
    }

    &.has-image {
      .name {
        width: calc(100% - 75px);

        @media (min-width: $break-sm) {
          width: calc(100% - 105px);
        }
      }
    }
  }
}
