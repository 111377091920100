#modal-config-cookies {
  .icon {
    width: 40px;
  }

  a {
    color: #009688;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .form-check, .form-check * {
    cursor: pointer;
  }
}