.blinker-superfast {
  animation: blink .2s linear infinite;
}
.blinker-fast {
  animation: blink .5s linear infinite;
}
.blinker-normal {
  animation: blink 1s linear infinite;
}
.blinker-slow {
  animation: blink 2s linear infinite;
}
@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}
@keyframes blink {
  50% {
    opacity: 0;
  }
}