#cookies-message {
  a {
    color: #009688;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: $break-lg) {
    .buttons {
      padding-top: 20px;
    }
  }
}