@import "../responsive-vars";

.search-form {
  .suggestions {
    box-shadow: 5px 5px 10px -5px #000000;

    @media (min-width: $break-lg) {
      margin-right: 0.75rem;
    }

    .suggestion {
      cursor: pointer;

      .suggestion-name {
        font-weight: bold;
        span {
          font-weight: normal;
        }
      }

      &.saved-search {
        .remove-saved-search {
          color: #adb5bd;
          vertical-align: top;
        }
        .suggestion-name {
          color: #4555b7;
        }
      }

      &:hover {
        background-color: #EEEEEE;
      }

      .remove-saved-search {
        cursor: pointer;
      }
    }
  }

  .searcher {
    .dropdown-content {
      outline: 1px solid #000000;
    }

    .dropdown-option {
      &:hover, &.selected {
        background-color: #E7E7E7;
        font-weight: bold;
      }
    }

    .btn-clear i {
      background-color: #cdcdcd;
      line-height: 25px;
      width: 25px;
    }
  }
}