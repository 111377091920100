#cart-progress {
  position: relative;

  .step-progress {
    position: absolute;
    height: 5px;
    background-color: $gray-lighter-color;
    z-index: -1;
    left: 50%;
    top: 15px;
    transform: translateX(-50%)!important;
    .bar {
      height: 100%;
      background-color: $color-purple;
    }
  }

  .step {
    text-align: center;
    flex: 1 0 0%;
    display: flex;
    justify-content: center!important;

    .step-number {
      width: 35px;
      line-height: 35px;
      display: block;
      margin: auto;
      background-color: $color-gray;
      border-radius: 50%;
      color: #FFF;
    }
    .step-name {
      font-weight: bold;
      color: $color-gray;
      word-wrap: break-word;
      word-break: break-word;
    }
    &.active {
      .step-number {
        background-color: $color-purple;
      }
      .step-name {
        color: $color-purple;
      }
    }
  }
}