@import "colors-vars";

.website-form {
  input:not([type='submit']) {
    border: 1px solid #000 !important;
    border-radius: 0 !important;
    padding: 0 5px !important;
    height: 32px !important;

    &::placeholder {
      color: #BFBFBF !important;
    }

    &:focus {
      border: 1px solid #009587 !important;
      border-bottom: 3px solid #009587 !important;
      box-shadow: none !important;
    }
  }

  .errors {
    background-color: #f44336 !important;
    color: hsla(0, 0%, 100%, .84) !important;
  }

  .success-messages {
    background-color: #4caf50 !important;
    color: hsla(0, 0%, 100%, .84) !important;
  }
}

.or-wrap {
  text-align: center;
  position: relative;
  & .text {
    padding: 0 22px;
    position: relative;
    display: inline-block;
    background-color: #fff;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid $gray-lighter-color;
    background: $gray-lighter-color;
    width: 100%;
    transform: translateY(-50%);
  }
}