#modal-cookies {

  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    border-radius: 0;
  }

  .ok-button {
    height: 58px !important;
    @media (min-width: $break-lg) {
      width: 400px !important;
      height: 44px !important;
    }
  }

  .tvi-logo-mobile {
    font-size: 52px;
  }
}