.bottom-90px {
  bottom: 90px;
}

.top-45 {
  top: 45%;
}

.position-inherit {
  position: inherit;
  top: inherit;
  right: inherit;
  bottom: inherit;
  left: inherit;
}

@media (min-width: $break-sm) {
  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-inherit {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

@media (min-width: $break-md) {
  .position-md-relative {
    position: relative !important;
  }

  .position-md-inherit {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

@media (min-width: $break-lg) {
  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-inherit {
    position: inherit !important;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

@media (min-width: $break-xl) {
  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-inherit {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}

@media (min-width: $break-xxl) {
  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-inherit {
    position: inherit;
    top: inherit;
    right: inherit;
    bottom: inherit;
    left: inherit;
  }
}
