@import "colors-vars";

.alert {
  &.error {
    background-color: $alert-error-bg !important;
    color: $alert-text-color !important;

    a {
      color: inherit;
    }
  }

  &.success {
    background-color: $alert-success-bg !important;
    color: $alert-text-color !important;

    a {
      color: inherit;
    }
  }
}