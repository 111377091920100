@import "../Social/icons";

footer {
  a {
    transition: all .2s;

    &:hover {
      opacity: .7;
    }
  }

  #footer-social {
    .social-link {
      transition: transform .2s;

      &:hover {
        transform: scale(1.5);
      }
    }
  }

  #footer-payment {
    img {
      max-height: 30px;
    }
  }
}